import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 17.5rem auto 7.5rem;
  padding: 0 10.972vw;

  @media (max-width: 1199px) {
    margin: 10rem auto 5.625rem;
    padding: 0 1.9375rem;
  }
`

const Wrapper = styled.div`
  max-width: 1024px;
  margin: auto;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;

  p {
    margin-top: inherit;
  }
  ul {
    list-style: none;
    li {
      padding-left: 0.8125rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark3};
        position: absolute;
        top: 13px;
        left: 0;
        transform: rotate(45deg);
      }
    }
  }
`
